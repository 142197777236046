import { pluck, distinctUntilChanged } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { State } from "./state";

const state: State = {
    processing: {
        open: false,
        title: ''
    },
    device: null,
    area: "bookings",
    productGroup: undefined,
    productGroups: [],
    product: undefined,
    products: [],
    packageToDelete: undefined,
    cropImage: undefined,
    counties: [],
    user: undefined,
    userToEdit: undefined,
    users: [],
    packages: [],
    menuOpen: true,
    menuHeaderOpen: true,
    menuOptionActive: undefined,
    menu: [
        // {name: 'Dashboard', route: '/dashboard', icon: 'bar_chart', active: false, addDivider: false},
        {
            permissionGroup: "bookings",
            name: "Bookings",
            route: "/bookings",
            icon: "assignment",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "bookings",
            name: "Occupancy",
            route: "/occupancy",
            icon: "assignment",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "createBooking",
            name: "Create booking",
            route: "/bookings/create",
            icon: "add_shopping_cart",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "createBooking",
            name: "Create payment link",
            route: "/bookings/paymentlink",
            icon: "currency_pound",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "createBooking",
            name: "Adyen payments",
            route: "/payments",
            icon: "currency_pound",
            active: false,
            addDivider: false,
            area: "bookings",
        },

        {
            permissionGroup: "hotels",
            name: "Hotels",
            route: "/hotels",
            icon: "location_city",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "rooms",
            name: "Rooms",
            route: "/rooms",
            icon: "meeting_room",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "hotelFeatures",
            name: "Hotel features",
            route: "/hotels/features",
            icon: "location_city",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        // {name: 'Room Types', route: '/room-types', icon: 'hotel', active: false, addDivider: false},
        {
            permissionGroup: "packages",
            name: "Promotions",
            route: "/packages",
            icon: "card_giftcard",
            active: false,
            addDivider: false,
            area: "marketing",
        },

        {
            permissionGroup: "productGroups",
            name: "Room add-ons",
            route: "/product-groups",
            icon: "shopping_basket",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "vouchers",
            name: "Vouchers",
            route: "/vouchers",
            icon: "credit_score",
            active: false,
            addDivider: false,
            area: "marketing",
        },
        {
            permissionGroup: "addOnReport",
            name: "Add ons report",
            route: "/product-groups/report",
            icon: "shopping_basket",
            active: false,
            addDivider: false,
            area: "marketing",
        },
        {
            permissionGroup: "reporting",
            name: "Reporting",
            route: "/reporting",
            icon: "download",
            active: false,
            addDivider: false,
            area: "marketing",
        },
        {
            permissionGroup: "events",
            name: "Events",
            route: "/events",
            icon: "calendar_month",
            active: false,
            addDivider: false,
            area: "events",
        },
        {
            permissionGroup: "eventBookings",
            name: "Event bookings",
            route: "/events/bookings",
            icon: "follow_the_signs",
            active: false,
            addDivider: false,
            area: "events",
        },
        {
            permissionGroup: "eventTypes",
            name: "Event types",
            route: "/events/types",
            icon: "calendar_month",
            active: false,
            addDivider: false,
            area: "events",
        },

        {
            permissionGroup: "rates",
            name: "Rate plans",
            route: "/rateplans",
            icon: "card_giftcard",
            active: false,
            addDivider: false,
            area: "bookings",
        },

        {
            permissionGroup: "testimonials",
            name: "Testimonials",
            route: "/testimonials",
            icon: "comments",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "cms",
            name: "CMS snippets",
            route: "/cms",
            icon: "web",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        {
            permissionGroup: "faq",
            name: "FAQ",
            route: "/faq",
            icon: "help",
            active: false,
            addDivider: false,
            area: "bookings",
        },
        // {
        //     permissionGroup: "cms",
        //     name: "Contact tile",
        //     route: "/contact",
        //     icon: "help",
        //     active: false,
        //     addDivider: false,
        //     area: "bookings",
        // },
        {
            permissionGroup: "users",
            name: "User management",
            route: "/users",
            icon: "people",
            active: false,
            addDivider: false,
            area: "settings",
        },
        {
            permissionGroup: "siteStatus",
            name: "Site status",
            route: "/site-down",
            icon: "play_circle",
            active: false,
            addDivider: false,
            area: "settings",
        },
        {
            permissionGroup: "rrSyncLog",
            name: "RR Sync Log",
            route: "/rr-sync",
            icon: "rss_feed",
            active: false,
            addDivider: false,
            area: "settings",
        },
        {
            permissionGroup: "passports",
            name: "Passports",
            route: "/passports",
            icon: "payment",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "passportSignUp",
            name: "Sign ups",
            route: "/passports/signups",
            icon: "how_to_reg",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "passportReprint",
            name: "Reprints",
            route: "/passports/reprints",
            icon: "print",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "passportUnconfirmed",
            name: "Unconfirmed",
            route: "/passports/unconfirmed",
            icon: "person_outline",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "passportStaff",
            name: "Staff",
            route: "/passports/staff",
            icon: "grade",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "passportSupplier",
            name: "Suppliers",
            route: "/passports/suppliers",
            icon: "domain",
            active: false,
            addDivider: false,
            area: "passport",
        },

        {
            permissionGroup: "passportStats",
            name: "Stats",
            route: "/passports/stats",
            icon: "bar_chart",
            active: false,
            addDivider: false,
            area: "passport",
        },
        {
            permissionGroup: "feedbackRequests",
            name: "Feedback requests",
            route: "/feedback/invites",
            icon: "comments",
            active: false,
            addDivider: false,
            area: "feedback",
        },
        {
            permissionGroup: "feedbackDistro",
            name: "Feedback distribution lists",
            route: "/feedback/business",
            icon: "location_city",
            active: false,
            addDivider: false,
            area: "feedback",
        },
        {
            permissionGroup: "giftcards",
            name: "Gift cards",
            route: "/giftcards",
            icon: "card_membership",
            active: false,
            addDivider: false,
            area: "giftcards",
        },
        {
            permissionGroup: "giftcards",
            name: "Gift card products",
            route: "/giftcards/products",
            icon: "card_membership",
            active: false,
            addDivider: false,
            area: "giftcards",
        },

        // {
        //     permissionGroup: "bookings",
        //     name: "Feedback templates",
        //     route: "/feedback/templates",
        //     icon: "web",
        //     active: false,
        //     addDivider: false,
        //     area: "feedback",
        // },
    ],
    asideOpen: false,
    growler: {
        errors: [],
        notifications: [],
    },
    featureGroups: [],
    featureGroup: {
        id: 0,
        name: "",
        icon: "",
    },
    overlays: {
        userCreate: false,
        userUpdate: false,
        roomTypeAdd: false,
        roomTypeEdit: false,
        roomFeatures: false,
        imageDelete: false,
        roomTypeDelete: false,
        imageCropper: false,
        featureGroup: false,
        productGroup: false,
        productGroupDelete: false,
        product: false,
        productDelete: false,
        featureGroupsSort: false,
        packageDelete: false,
    },
    roomTypes: [],
    roomType: undefined,
    rooms: [],
    room: undefined,
    hotels: [],
    hotel: undefined,
    bookings: [],
    booking: undefined,
    prebookings: [],
    prebooking: undefined,
    bookingParams: {
        hasSpa: 0,
        ratePlanCode: '',
        searchTerm: "",
        name: "",
        hotelID: "",
        upgraded: "",
        hasExtras: "",
        hasPromo: "",
        giftcard: '',
        county: "",
        bookingStartDate: undefined,
        bookingEndDate: undefined,
        startDate: undefined,
        endDate: undefined,
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "booking.created DESC",
        sorts: [
            { ID: "booking.created DESC", name: "Recent First" },
            { ID: "booking.created ASC", name: "Recent Last" },
            { ID: "hotel.name ASC", name: "Hotel Name A-Z" },
            { ID: "hotel.name DESC", name: "Hotel Name Z-A" },
            { ID: "county.name DESC", name: "County A-Z" },
            { ID: "county.name ASC", name: "County Z-A" },
        ],
    },
    preBookedParams: {
        searchTerm: "",
        name: "",
        hotelID: "",
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "prebooking.created DESC",
        sorts: [
            { ID: "prebooking.created DESC", name: "Recent First" },
            { ID: "prebooking.created ASC", name: "Recent Last" },
            { ID: "hotel.name ASC", name: "Hotel Name A-Z" },
            { ID: "hotel.name DESC", name: "Hotel Name Z-A" },
        ],
    },
    hotelParams: {
        searchTerm: "",
        name: "",
        county: "",
        limit: 20,
        limits: [20, 30, 40],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "hotel.name ASC",
        sorts: [
            { ID: "hotel.name ASC", name: "Hotel Name A-Z" },
            { ID: "hotel.name DESC", name: "Hotel Name Z-A" },
            { ID: "county.name DESC", name: "County A-Z" },
            { ID: "county.name ASC", name: "County Z-A" },
        ],
    },
    roomParams: {
        searchTerm: "",
        name: "",
        hotel: "",
        hotelID: "",
        limit: 20,
        limits: [20, 30, 40],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "room.sortOrder ASC",
        sorts: [
            { ID: "room.sortOrder ASC", name: "Sort Order A-Z" },
            { ID: "room.name ASC", name: "Hotel Name A-Z" },
            { ID: "room.name DESC", name: "Hotel Name Z-A" },
            { ID: "hotel.name DESC", name: "Hotel A-Z" },
            { ID: "hotel.name ASC", name: "Hotel Z-A" },
        ],
    },
    chartData: {
        studentAge: {
            total: 20,
            labels: [],
            data: [],
        },
        schoolYear: {
            total: 20,
            labels: ["Year 6", "Year 7", "Year 8", "Year 9"],
            data: [31, 44, 29, 55],
        },
        schoolCatchment: {
            total: 20,
            labels: ["Kempston", "Bromham", "Biddeham", "Bedford"],
            data: [31, 44, 29, 55],
        },
        gender: {
            total: 20,
            labels: ["Female", "Male"],
            data: [75, 84],
        },
    },
    faq: {
        data: [],
        params: {
            freetext: '',
            id: 0,
            limit: 8,
            limits: [8, 16, 24, 32, 64],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: 'faq_topic.sortOrder',
            sorts: [],
        },
    },
    faqQuestion: undefined,
    faqQuestions: [],

    basketMeta: {},
    basket: {
        version: 1,
        payUrl: '',
        payUrlId: '',
        fixedPackageSelected: false,
        fixedPackageHidden: false,
        fixedPackageMode: 'banner',
        fixedPackageApplied: false,
        comments: '',
        packagesSelected: [],
        packagesQualified: [],
        items: [],
        giftCards: [],
        voucher: undefined,
        userId: '',
        discountCs: {
            rateOnly: false,
            type: 'perc',
            percValue: 0,
            typeValue: 0,
            discountValue: 0,
        },
        checkoutTotals: {
            paid: {
                giftCard: 0,
                voucher: 0,
                card: 0,
                events: 0,
                treatments: 0,
                total: 0,
            },
            unpaid: {
                total: 0,
            },
            deposit: {
                originalRoom: 0,
                checkout: 0,
            },
            discount: {
                package: 0,
                cs: 0,
                passport: 0,
                voucher: 0,
                addOns: 0,
                events: 0,
                treatments: 0,
                total: 0,
            },
            costs: {
                room: 0,
                addOns: 0,
                events: 0,
                treatments: 0,
                supplements: {
                    child: 0,
                    dog: 0,
                    bubbleAndSqueak: 0,
                    guest: 0,
                    total: 0,
                },
                total: 0,
            },
        },
        totals: {
            toPay: 0,
            deposit: 0,
            discount: 0,
            giftCard: undefined,
            giftCardDiscount: 0,
            discountPackage: 0,
            discountPassport: 0,
            paid: 0,
            total: 0,
            addOnsTotal: 0,
            supplements: {
                child: 0,
                dog: 0,
            }
        },
        fullPaymentRequired: false,
        allowPaymentChoice: false,
        fullPaymentSelected: false,
        depositFixedValue: 0,
        depositPaymentType: 'standard',
    },
    checkoutTotals: {
        paid: {
            giftCard: 0,
            voucher: 0,
            card: 0,
            events: 0,
            treatments: 0,
            total: 0,
        },
        unpaid: {
            total: 0,
        },
        deposit: {
            originalRoom: 0,
            checkout: 0,
            fixed: 0,
        },
        discount: {
            package: 0,
            cs: 0,
            passport: 0,
            voucher: 0,
            addOns: 0,
            events: 0,
            treatments: 0,
            total: 0,
        },
        costs: {
            room: 0,
            addOns: 0,
            events: 0,
            treatments: 0,
            supplements: {
                child: 0,
                dog: 0,
                bubbleAndSqueak: 0,
                guest: 0,
                total: 0,
            },
            total: 0,
        },
    },
    ratePlans: [],
    ratePlansUpsell: [],
    availabilitySevenDay: {},
    availabilityPromotions: {},
    availabilitySearch: {
        checkIn: new Date(),
        checkInDay: 0,
        dateKey: new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '|' + 1,
        nights: 1,
        adults: 2,
        children: 0,
        dogs: 0,
        type: 'BB',
        room: undefined,
        hotel: undefined,
        roomAvailable: false,
        roomCost: 0,
        nightsToStay: [],
        basket: {
            total: 0,
            paid: 0,
            deposit: 0,
            items: [],
        },
        prebooking: {
            selected: false,
            telephone: '',
            bestTime: 1,
            items: []
        }
    },
    adjustments: [],
    basketBooking: {
        hotelId: '',
        roomType: '',
        roomAvailable: false,
        roomCostForStay: 0,
        roomCostPerNight: 0,
    },
    vouchersAvailable: []
};

export class Store {
    private subject = new BehaviorSubject<State>(state);
    private store = this.subject.asObservable().pipe(distinctUntilChanged());

    get value() {
        return this.subject.value;
    }

    select<T>(name: string): Observable<T> {
        return this.store.pipe(pluck(name));
    }

    selectForLocal(name: string) {
        const d = this.subject.value;
        return d[name];
    }

    set(name: string, state: any) {
        this.subject.next({
            ...this.value,
            [name]: state,
        });
    }
}
