import { Component, OnInit } from '@angular/core';
import { AdyenService } from '../services/adyen.service';

@Component({
    selector: 'app-adyen-payment-report',
    templateUrl: './adyen-payment-report.component.html',
    styleUrls: ['./adyen-payment-report.component.css']
})
export class AdyenPaymentReportComponent implements OnInit {

    params = {
        searchTerm: '',
        name: '',
        hotelID: '',
        upgraded: '',
        hasPromo: '',
        hasExtras: '',
        county: '',
        startDate: undefined,
        endDate: undefined,
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'adyen_booking_link.createdAt DESC',
        sorts: [
            { ID: 'adyen_booking_link.createdAt DESC', name: 'Recent First' },
            { ID: 'adyen_booking_link.createdAt ASC', name: 'Recent Last' },
            { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'county.name DESC', name: 'County A-Z' },
            { ID: 'county.name ASC', name: 'County Z-A' },
        ],
    };

    payments = [];

    constructor(
        private adyenService: AdyenService,
    ) { }

    ngOnInit(): void {
        this.search()
    }


    search() {
        this.adyenService.searchPayments(this.params).subscribe(data => {
            console.log(data)
            this.payments = data.data;
            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

}
