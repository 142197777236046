<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/payments']">Payments</a>
        </div>
        <div class="col-md-5 text-right">

        </div>
    </div>
</mat-card>


<mat-card class="no-records" *ngIf="!payments.length">
    No payments Listed
</mat-card>

<ng-container *ngIf="payments.length">

    <div class="margin-top-20">
        <app-pagination [params]="params" (update)="search()"></app-pagination>


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <td>Paid</td>
                <th>Created</th>
                <th>Value Paid</th>
                <th>Brand</th>
                <th>Customer</th>
                <th>Email</th>
                <th>Payment Link</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of payments">
                <td>
                    <i class="fa fa-check text-success" *ngIf="p.paid > 0"></i>
                </td>
                <td class="hover">{{p.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="hover">{{p.paid}}</td>
                <td class="hover">{{p.hotelName}}</td>
                <td class="hover">{{p.firstname}} {{p.surname}}</td>
                <td class="hover">{{p.username}}</td>
                <td class="hover"><a [href]="'https://eu.adyen.link/' + p.payUrlId" target="_blank">{{p.payUrlId}}</a></td>
            </tr>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>
    </div>


</ng-container>
